// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.pointer{
  cursor: pointer;
}
.submitButton{
  font-size: 16px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
        /* Style the grid container */
        .grid-container {
          display: grid;
          gap: 20px;
          margin-top: 20px;
          width: 80%;
          margin: 1px auto;
      }
      
      /* Style the grid items */
      .grid-item {
          border: 1px solid #ccc;
          padding: 10px;
          text-align: center;
      }

      .grid-container-mail{
        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        gap: 20px;
      }
.email-details{
  border: 1px solid #ccc; padding: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;QACQ,6BAA6B;QAC7B;UACE,aAAa;UACb,SAAS;UACT,gBAAgB;UAChB,UAAU;UACV,gBAAgB;MACpB;;MAEA,yBAAyB;MACzB;UACI,sBAAsB;UACtB,aAAa;UACb,kBAAkB;MACtB;;MAEA;QACE,kCAAkC;QAClC,aAAa;QACb,SAAS;MACX;AACN;EACE,sBAAsB,EAAE,aAAa;AACvC","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n.pointer{\n  cursor: pointer;\n}\n.submitButton{\n  font-size: 16px;\n  padding: 10px 20px;\n  background-color: #007bff;\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n        /* Style the grid container */\n        .grid-container {\n          display: grid;\n          gap: 20px;\n          margin-top: 20px;\n          width: 80%;\n          margin: 1px auto;\n      }\n      \n      /* Style the grid items */\n      .grid-item {\n          border: 1px solid #ccc;\n          padding: 10px;\n          text-align: center;\n      }\n\n      .grid-container-mail{\n        grid-template-columns: 1fr 1fr 1fr;\n        display: grid;\n        gap: 20px;\n      }\n.email-details{\n  border: 1px solid #ccc; padding: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
